"use strict";

/*** Smooth Scroll ***/
$('a[href*="#"]') // Remove links that don't actually link to anything
.not('[href="#"]').not('[href="#0"]').click(function (event) {
  // On-page links
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']'); // Does a scroll target exist?

    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      $('html, body').animate({
        scrollTop: target.offset().top - 75
      }, 1000);
    }
  }
}); // /*** IE Custom Object Fit ***/
//
// var userAgent, ieReg, ie;
// userAgent = window.navigator.userAgent;
// ieReg = /msie|Trident.*rv[ :]*11\./gi;
// ie = ieReg.test(userAgent);
//
// if (ie) {
// 	$(".full-image-container").each(function () {
// 		var $container = $(this),
// 			imgUrl = $container.find("img").prop("src");
// 		if (imgUrl) {
// 			$container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("custom-object-fit");
// 		}
// 	});
// }

/*** Fancybox ***/

$.fancybox.defaults.loop = true;
$.fancybox.defaults.infobar = false;
$.fancybox.defaults.tooolbar = true;
/*** Platform Overview ***/

$(".scroll-header").headroom();